import { useRoutePatchMutation } from "api/call-center/routes/hooks";
import { CallCenterRouteOrderListItem } from "api/call-center/routes/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiMail } from "components/miloDesignSystem/atoms/icons/MdiMail";
import { useSelector } from "hooks";
import { useState } from "react";
import { cx, dateFns } from "utilities";
import CopyToClipboardComponent from "react-copy-to-clipboard";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "pages/callCenter/shared/CallCenter.module.css";
import { isFromLastXHours } from "../utils/isFromLastFourHours";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Button } from "components/miloDesignSystem/atoms/button";
import { OrdersDriver } from "../components/OrdersDriver";
import { ExpectedDelivery } from "../components/ExpectedDelivery";
import { ConfirmationStatus } from "../components/ConfirmationStatus";
import { RejectionStatus } from "../components/RejectionStatus";
import { ResponsibleForRoute } from "../../shared/components/responsibleForRoute/ResponsibleForRoute";
import { CustomSwitch as Switch } from "components/utils/customSwitch";
import { CallStatusSelector } from "../../shared/components/callStatusSelector/CallStatusSelector";

export const useRouteOrdersColumns = (
  callInProgress: number | null,
  setCallInProgress: React.Dispatch<React.SetStateAction<number | null>>,
  unreadSmsModal: {
    isOpen: boolean;
    open: (stateToSet: { orderId: number }) => void;
    close: () => void;
    state: {
      orderId: number;
    } | null;
  },
) => {
  const isCallRequiredMutation = useRoutePatchMutation();
  const callStatusMutation = useRoutePatchMutation();
  const me = useSelector(store => store.auth.user!);
  const [isAnimating, setIsAnimating] = useState("");

  return useCreateTableColumns<CallCenterRouteOrderListItem>(({ columnHelper }) => {
    return [
      columnHelper.text(row => `${row.routePosition}.`, {
        header: "#",
        size: 20,
      }),
      columnHelper.accessor(row => row, {
        header: "",
        id: "unreadMessages",
        size: 30,
        cell: info => {
          const order = info.getValue();
          return (
            <div>
              <IconButton
                icon={
                  <MdiMail
                    color={order.hasUnreadMessages ? "green400" : "neutralBlack100"}
                    size="16"
                  />
                }
                onClick={event => {
                  event.stopPropagation();

                  if (order.hasUnreadMessages) {
                    unreadSmsModal.open({ orderId: order.id });
                  }
                }}
                variant="transparent"
              />
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "nr zamówienia",
        size: 180,
        cell: info => (
          <div
            className="d-flex align-items-center gap-1"
            onClick={event => event.stopPropagation()}
          >
            <CopyToClipboardComponent text={info.getValue().signature}>
              <div
                className="d-flex align-items-center justify-content-between"
                onClick={() => setIsAnimating(info.getValue().id.toString())}
              >
                <Tooltip title="Kopiuj numer zamówienia">
                  <div onMouseDown={event => event.stopPropagation()}>
                    <Typography fontSize="12" fontWeight="500" noWrap>
                      {info.getValue().signature}
                    </Typography>
                  </div>
                </Tooltip>

                {String(info.getValue().id) === isAnimating && (
                  <div
                    className={cx(styles.checkIcon, {
                      [styles.successCheckAnimation]: isAnimating,
                    })}
                    onAnimationEnd={() => setIsAnimating("")}
                  />
                )}
              </div>
            </CopyToClipboardComponent>
            {info.getValue().pinnedToRouteDate &&
              isFromLastXHours(info.getValue().pinnedToRouteDate!, 3) && (
                <Tag label="ost. dodane" variant="info" />
              )}
          </div>
        ),
      }),
      columnHelper.accessor(row => row, {
        header: "wymagany tel.",
        size: 135,
        cell: info => {
          const order = info.getValue();
          return (
            <div
              className="d-flex align-items-center gap-3 cursor-pointer"
              onClick={event => event.stopPropagation()}
              onMouseDown={event => event.stopPropagation()}
            >
              <Switch
                checked={order.isCallRequired}
                disabled={
                  isCallRequiredMutation.isLoading &&
                  String(order.id) === isCallRequiredMutation.variables?.id.toString()
                }
                label={order.isCallRequired ? "Tak" : "Nie"}
                name="isCallRequired"
                onChange={isCallRequired => {
                  isCallRequiredMutation.mutate({
                    id: order.id,
                    toUpdate: {
                      isCallRequired,
                    },
                  });
                }}
              />
              <CallStatusSelector orderListItem={order} />
            </div>
          );
        },
      }),
      columnHelper.text(row => row.delivery.phone, {
        header: "telefon",
        size: 110,
      }),
      columnHelper.accessor(row => row, {
        header: "",
        id: "callInProgress",
        size: 120,
        cell: info => (
          <div className="d-flex align-items-center" onMouseDown={event => event.stopPropagation()}>
            {info.getValue().id === callInProgress ? (
              <Tag label="W trakcie" variant="success" />
            ) : (
              <Button
                className="text-uppercase"
                onClick={event => {
                  const date = new Date();
                  setCallInProgress(info.getValue().id);
                  callStatusMutation.mutate({
                    id: info.getValue().id,
                    toUpdate: {
                      callStatus: "ON_A_CALL",
                      calledAt: date.toJSON(),
                      hasCalled: true,
                      responsible: me,
                    },
                  });
                  event.stopPropagation();
                }}
                size="small"
                theme="light"
                variant="gray"
              >
                Zadzwoń teraz
              </Button>
            )}
          </div>
        ),
      }),
      columnHelper.text(
        row => {
          if (!Boolean(row.externalId.length)) return null;
          return row.externalId;
        },
        {
          header: "nr zewn.",
          size: 80,
        },
      ),
      columnHelper.text(
        row => {
          if (!Boolean(row.delivery.fistName.length) && !Boolean(row.delivery.lastName.length))
            return null;
          return `${row.delivery.fistName} ${row.delivery.lastName}`;
        },
        {
          header: "klient",
          size: 100,
        },
      ),
      columnHelper.text(
        row => {
          if (
            !row.deliveryConfirmationDeadline ||
            !Boolean(row.deliveryConfirmationDeadline.length)
          )
            return null;
          return dateFns.format(new Date(row.deliveryConfirmationDeadline), "d.MM, H:mm");
        },
        {
          header: "potwierdzić do",
          size: 90,
        },
      ),
      columnHelper.accessor(row => row.driver, {
        header: "kierowca",
        size: 160,
        cell: info => <OrdersDriver driver={info.getValue()} />,
      }),
      columnHelper.accessor(row => row, {
        header: "spodziewana dostawa",
        size: 250,
        cell: info => <ExpectedDelivery order={info.getValue()} />,
      }),
      columnHelper.accessor(row => row.hasSmsSent, {
        header: "wiadomość",
        size: 100,
        cell: info => {
          if (info.getValue()) {
            return <Tag label="wysłano" variant="success" />;
          }
          return <Tag label="nie wysłano" variant="outline" />;
        },
      }),
      columnHelper.accessor(row => row, {
        header: "potwierdzenie",
        size: 105,
        cell: info => <ConfirmationStatus order={info.getValue()} />,
      }),
      columnHelper.accessor(row => row, {
        header: "odrzucenie",
        size: 85,
        cell: info => <RejectionStatus order={info.getValue()} />,
      }),
      columnHelper.accessor(row => row, {
        header: "odpowiedzialny",
        size: 120,
        cell: info => <ResponsibleForRoute order={info.getValue()} />,
      }),
    ];
  });
};
