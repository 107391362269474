import { IndexForExternalManufacturingListItem } from "api/indexes/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Attributes } from "components/miloDesignSystem/molecules/attributes";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { indexesUtils } from "./utils/indexesUtils";

export const useIndexesColumns = () => {
  return useCreateTableColumns<IndexForExternalManufacturingListItem>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.product.name, {
        header: "nazwa",
        size: 400,
        typographyProps: {
          fontSize: "14",
          fontWeight: "600",
        },
      }),
      columnHelper.accessor(row => row.verboseAttributesValues, {
        header: "atrybuty",
        size: 400,
        cell: info => {
          const verboseAttributesValues = info.getValue();

          if (!Boolean(verboseAttributesValues.length)) return <EmptyValue fontSize="14" />;

          return <Attributes attributes={verboseAttributesValues} />;
        },
      }),
      columnHelper.text(row => row.ean, {
        header: "EAN",
        size: 115,
        typographyProps: {
          fontSize: "14",
          fontWeight: "400",
        },
      }),
      columnHelper.accessor(row => row.manufacturer, {
        header: "producent",
        size: 130,
        cell: info => {
          const manufacturer = info.getValue();

          if (!manufacturer) return <EmptyValue fontSize="14" />;

          return (
            <div className="d-flex align-items-center gap-1 cut-text">
              <Typography fontSize="14" fontWeight="700" noWrap>
                {manufacturer.name}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.currentInProduction, {
        id: "previouslyOrdered",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            wcześniej zamówione
          </Typography>
        ),
        size: 170,
        cell: info => {
          const currentInProduction = info.getValue();

          if (!currentInProduction)
            return <EmptyValue className="text-right w-100" color="neutralBlack48" fontSize="14" />;

          return (
            <div className="d-flex align-items-baseline justify-content-end w-100 gap-1">
              <Typography color="info400" fontSize="14" fontWeight="600">
                {currentInProduction.withOrder + currentInProduction.withoutOrder}
              </Typography>
              {currentInProduction.withOrder !== 0 && currentInProduction.withoutOrder !== 0 && (
                <Typography fontSize="12" fontWeight="700">
                  ({currentInProduction.withOrder} z zam., {currentInProduction.withoutOrder} wolne)
                </Typography>
              )}
              <Typography color="neutralBlack48" fontSize="10" fontWeight="500">
                szt.
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.warehouseState, {
        id: "state",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            stan
          </Typography>
        ),
        size: 85,
        cell: info => {
          const warehouseState = info.getValue();

          return (
            <div className="d-flex align-items-center justify-content-end w-100 gap-1">
              <div className="d-flex align-items-baseline gap-1">
                <Typography color="neutralBlack64" fontSize="14" fontWeight="400">
                  {warehouseState.state}
                </Typography>
                <Typography color="neutralBlack48" fontSize="10" fontWeight="500">
                  szt.
                </Typography>
              </div>
              <div>{indexesUtils.getWarehouseStateLegend({ warehouseState }).icon}</div>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.warehouseState, {
        id: "reserved",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            zarezerwowane
          </Typography>
        ),
        size: 85,
        cell: info => {
          const warehouseState = info.getValue();

          return (
            <div className="d-flex align-items-baseline justify-content-end w-100 gap-1">
              <Typography color="neutralBlack64" fontSize="14" fontWeight="400">
                {warehouseState.reservation}
              </Typography>
              <Typography color="neutralBlack48" fontSize="10" fontWeight="500">
                szt.
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.salesFromLastTwoWeeks, {
        id: "salesFromLastTwoWeeks",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            sprzedaż (ost. 2 tyg.)
          </Typography>
        ),
        size: 130,
        cell: info => {
          const salesFromLastTwoWeeks = info.getValue();

          return (
            <div className="d-flex align-items-baseline justify-content-end w-100 gap-1">
              <Typography color="neutralBlack64" fontSize="14" fontWeight="600">
                {salesFromLastTwoWeeks}
              </Typography>
              <Typography color="neutralBlack48" fontSize="10" fontWeight="500">
                szt.
              </Typography>
            </div>
          );
        },
      }),
    ];
  });
};
