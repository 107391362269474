import {
  EMPTY_VALUE,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import styled from "@emotion/styled";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import styles from "./RouteProgress.module.css";
import { cx, dateFns } from "utilities";
import { useManufacturingStages } from "api/manufacturingNew/hooks";
import { ManufacturingStage } from "api/manufacturing/schemas/models";
import { MdiCheckCircle } from "components/miloDesignSystem/atoms/icons/MdiCheckCircle";
import { MdiSchedule } from "components/miloDesignSystem/atoms/icons/MdiSchedule";
import { MdiSettings } from "components/miloDesignSystem/atoms/icons/MdiSettings";
import { ColumnDef } from "@tanstack/react-table";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { ReactNode } from "react";
import { ItemProgress, ManufacturingItemProgress } from "api/orders/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { productionOrdersActions } from "api/production-orders/hooks";
import { StageStatus } from "api/manufacturing/units/models";

export const useProductionProgressColumns = () => {
  const { data } = useManufacturingStages("");
  const createManufacturerOrdersMutation = productionOrdersActions.useCreateProductionOrders();
  return useCreateTableColumns<ManufacturingItemProgress>(({ columnHelper }) => {
    const columns: ColumnDef<ManufacturingItemProgress>[] = data.map((stage, index) =>
      columnHelper.accessor(row => row, {
        header: stage.name,
        size: 141,
        cell: info => {
          const row = info.getValue();
          if (index === 0) {
            const width =
              info.table.getTotalSize() -
              CLIENT_COLUMN_WIDTH -
              ORDER_SIGNATURE_COLUMN_WIDTH -
              SEND_FOR_PRODUCTION_STATUS_COLUMN_WIDTH -
              PRODUCTION_STATUS_COLUMN_WIDTH;

            return (
              <div className="d-flex flex-column flex-1 position-relative">
                <div className={styles.productName} style={{ width: `${width}px` }}>
                  <Typography fontSize="14" fontWeight="700">
                    {row.index.product.name}
                    {row.index.attributes.map((attribute, index) => (
                      <>
                        <Typography
                          key={`${attribute.attribute}: ${attribute.value}`}
                          fontSize="14"
                          fontWeight="500"
                          color="neutralBlack64"
                          className="px-2 position-relative"
                        >
                          {`${attribute.attribute}: ${attribute.value}`}
                          {index !== row.index.attributes.length - 1 && (
                            <div className={cx("separator", styles.separator)} />
                          )}
                        </Typography>
                      </>
                    ))}
                  </Typography>
                </div>

                {Boolean(row.manufacturingItem) ? (
                  <Stage
                    stage={stage}
                    progress={row?.manufacturingItem ? row?.manufacturingItem?.[stage.id] : null}
                  />
                ) : (
                  <StageSection className="pl-0">
                    <Button
                      size="small"
                      variant="deepPurple"
                      isLoading={
                        createManufacturerOrdersMutation.isLoading &&
                        createManufacturerOrdersMutation.variables?.items[0].indexId ===
                          row.index.id
                      }
                      onClick={() => {
                        createManufacturerOrdersMutation.mutate({
                          items: [
                            {
                              id: row.item.id,
                              indexId: row.index.id,
                              isSelfProduced: true,
                              manufacturer: null,
                              number: 1,
                              order: row.order!.id,
                              externalNumber: row.item.externalNumber,
                            },
                          ],
                        });
                      }}
                    >
                      Zleć produkcję
                    </Button>
                  </StageSection>
                )}
              </div>
            );
          }

          if (!Boolean(row.manufacturingItem)) return null;

          return (
            <Stage
              stage={stage}
              progress={row?.manufacturingItem ? row?.manufacturingItem?.[stage.id] : null}
            />
          );
        },
      }),
    );

    return [
      columnHelper.accessor(row => row, {
        header: "zamówienie | nr zlecenia",
        size: ORDER_SIGNATURE_COLUMN_WIDTH,
        cell: info => {
          const row = info.getValue();
          return (
            <div>
              <Typography fontSize="16" fontWeight="700">
                {row.order?.signature || EMPTY_VALUE}
              </Typography>
              <Typography fontSize="14" fontWeight="700">
                {row.signature || EMPTY_VALUE}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "status zlecenia",
        size: SEND_FOR_PRODUCTION_STATUS_COLUMN_WIDTH,
        cell: info => {
          const row = info.getValue();
          if (Boolean(row.manufacturingItem)) {
            return <Tag label="zlecono" variant="info" type="outlined" />;
          }

          return <Tag label="nie zlecono" variant="quaternary" type="outlined" />;
        },
      }),
      columnHelper.accessor(row => row, {
        header: "status produkcji",
        size: PRODUCTION_STATUS_COLUMN_WIDTH,
        cell: info => {
          const row = info.getValue();
          const progressList = Object.values(row.manufacturingItem || {});
          if (progressList.every(({ status }) => status === StageStatus.NOT_STARTED))
            return itemStatusToTagDict[StageStatus.NOT_STARTED];

          if (progressList.every(({ status }) => status === StageStatus.FINISHED))
            return itemStatusToTagDict[StageStatus.FINISHED];

          return itemStatusToTagDict[StageStatus.IN_PROGRESS];
        },
      }),
      columnHelper.accessor(row => row, {
        id: "client",
        header: () => (
          <Typography className="pr-1" color="neutralBlack48" fontSize="12" fontWeight="400">
            <div className={cx(styles.border, styles["borderHeader"])} />
            klient
          </Typography>
        ),
        size: CLIENT_COLUMN_WIDTH,
        cell: info => {
          const row = info.getValue();
          const displayName = row.order?.customer
            ? row.order?.customer?.name
            : `${row.order?.delivery.firstName} ${row.order?.delivery.lastName}`;
          return (
            <div className="pr-1">
              <div className={cx(styles.border)} />
              <Typography fontSize="14" fontWeight="400">
                {displayName || EMPTY_VALUE}
              </Typography>
              <Typography fontSize="12" fontWeight="600">
                {row.item?.externalNumber || EMPTY_VALUE}
              </Typography>
            </div>
          );
        },
      }),
      ...columns,
    ];
  });
};

const Stage = ({
  progress,
  stage,
}: {
  progress: ItemProgress | null;
  stage: ManufacturingStage | null;
}) => {
  if (!progress) {
    return (
      <NoStage>
        <Typography fontSize="12" fontWeight="600" color="neutralBlack32">
          nie dotyczy
        </Typography>
      </NoStage>
    );
  }

  if (progress.status === StageStatus.IN_PROGRESS) {
    return (
      <StageSection>
        <StageInProgressBackground backgroundColor={stage?.backgroundColor || ""} />
        <div className={styles.loader}></div>

        <WorkerInitials progress={progress} />
        <div className="mr-1">
          <MdiSettings color="deepPurple400" size="14" />
        </div>
      </StageSection>
    );
  }

  if (progress.status === StageStatus.NOT_STARTED || progress.status === StageStatus.READY) {
    return (
      <StageNotStarted>
        <WorkerInitials progress={progress} />

        <div className="mr-1">
          <MdiSchedule color="neutralBlack32" size="14" />
        </div>
      </StageNotStarted>
    );
  }

  return (
    <StageFinished backgroundColor={stage?.backgroundColor || ""}>
      <div className="d-flex align-items-center">
        <WorkerInitials progress={progress} />
        <Typography fontSize="10" fontWeight="400">
          {progress.finishedAt && dateFns.format(new Date(progress.finishedAt), "dd.MM HH:mm")}
        </Typography>
      </div>
      <div
        className={cx("d-flex align-items-center justify-content-center", styles.progressContainer)}
      >
        <MdiCheckCircle color="success300" size="14" />
      </div>
    </StageFinished>
  );
};

const WorkerInitials = ({ progress }: { progress: ItemProgress }) => {
  return (
    <Typography fontSize="12" fontWeight="700" className="mr-1">
      {progress.employee?.firstName[0]}
      {progress.employee?.lastName[0]}
    </Typography>
  );
};

const StageSection = styled.div`
  position: relative;
  display: flex;
  margin-top: 32px;
  flex: 1;
  width: 141px;
  min-width: 141px;
  max-width: 141px;
  align-items: center;
  justify-content: space-between;
  height: 26px;
  min-height: 26px;
  max-height: 26px;
  padding-left: 8px;
`;

const StageNotStarted = styled(StageSection)`
  border: 1px solid ${colorPalette.neutralBlack24};
`;

const StageInProgressBackground = styled.div<{
  backgroundColor: string;
}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.backgroundColor};
  opacity: 0.3;
`;

const StageFinished = styled(StageSection)<{
  backgroundColor: string;
}>`
  background-color: ${props => props.backgroundColor};
`;

const NoStage = styled(StageSection)`
  opacity: 0.5;
  border: 1px solid ${colorPalette.neutralBlack7};
`;

const itemStatusToTagDict: Record<StageStatus, ReactNode> = {
  [StageStatus.FINISHED]: <Tag label="Wyprodukowano" variant="success" />,
  [StageStatus.IN_PROGRESS]: <Tag label="W trakcie" variant="warning" />,
  [StageStatus.NOT_STARTED]: <Tag label="Nie rozpoczęto" variant="quaternary" />,
  [StageStatus.READY]: <Tag label="Nie rozpoczęto" variant="quaternary" />,
};

const ORDER_SIGNATURE_COLUMN_WIDTH = 154;
const PRODUCTION_STATUS_COLUMN_WIDTH = 110;
const SEND_FOR_PRODUCTION_STATUS_COLUMN_WIDTH = 86;
const CLIENT_COLUMN_WIDTH = 158;
