import { countryCodes } from "CONSTANTS";
import { ManufacturingItemStatus } from "api/orders/models";
import { getRoute, getRoutesSimplifiedItems } from "api/routes/calls";
import { filterFactory } from "components/utils/withFilters";

export const filterList = filterFactory(({ FilterType }) => [
  {
    type: FilterType.Select,
    kind: "label",
    label: "status",
    name: "status",
    options: [
      { label: "nierozpoczęty", value: ManufacturingItemStatus.PENDING },
      { label: "w trakcie", value: ManufacturingItemStatus.IN_PROGRESS },
      { label: "zakończony", value: ManufacturingItemStatus.DONE },
    ],
  },
  {
    type: FilterType.AsyncSearch,
    fetchListFrom: getRoutesSimplifiedItems,
    fetchDetailsFrom: getRoute,
    label: "Numer trasy",
    multiple: false,
    name: "route",
    value: "id",
    display: "signature",
    placeholder: "Szukaj trasy",
  },
  {
    type: FilterType.Select,
    label: "Czy zlecenie wymaga poprawy?",
    name: "isDeclined",
    options: [
      { label: "Tak", value: "true" },
      { label: "Nie", value: "false" },
    ],
  },
  {
    type: FilterType.Select,
    kind: "label",
    label: "priorytet",
    multiple: true,
    name: "priorities",
    options: [
      { label: "krytyczny", value: "A" },
      { label: "pilny", value: "B" },
      { label: "zwykły", value: "C" },
    ],
  },
  {
    type: FilterType.Select,
    label: "kraj",
    name: "countryCodes",
    multiple: true,
    kind: "label",
    options: countryCodes.map(({ icon, value }) => ({
      label: value,
      value,
      icon,
    })),
  },
]);
